import * as React from 'react';
import Hero from '../components/hero/hero';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

const AboutPage = () => {
  return (
    <>
      <Header hideNav={true} />
      <div className="container-fluid p-0">
        <Hero background="cloppus_cover@2x.jpg" />
        <div className="container">
        <div className="row justify-content-center py-5">
            <div className="col-10 d-flex justify-content-center text-center">
              <h2><i><strong>Cloppus:</strong> [from Late Latin] limping, that does not stand up right.</i></h2>
              </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <p>
                Cloppus is a design workshop born in 2020 from the need to work
                with matter as well as ideas.
              </p>

              <p>
                Our design activity stems from curiosity about how to solve
                specific design problems or to decline a particular theme. In
                this way, it often happens to develop spontaneous projects as
                well as work on request. We mainly work on lighting, furniture
                and product design.
              </p>

              <p>
                We love natural materials and we particularly love wood. The
                respect for the material we work with is our main target and our
                policy is to work with reclaimed and local sourced wood as much
                as possible.
              </p>
            </div>
          </div>
          <div className="row justify-content-center my-5 vertical-isolation">
            <div className="col-10 d-flex justify-content-center text-center">
              <p>Our website is currently under construction.<br/> For any inquiries and orders please contact us at <strong>info&#x00040;cloppus.com</strong><br/> or find us on Instagram at <strong>@cloppus.design</strong></p>
              </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
